<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ['data', 'labels', 'changeScaleUnit', 'changeTempUnit', 'changeGlucoseUnit', 'changeCholesterolUnit'],
  mounted() {
    this.renderLineChart()
  },
  methods: {
    renderLineChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.labels.length === 0 ? [] : this.newData
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: "Chart Data"
          }
        }
      );
    }
  },
  computed: {
    newData () {
      return this.data
    }
  },
  watch: {
    labels (val, oldVal) {
      if (val !== oldVal){
        this.renderLineChart()
      }
    },
    changeScaleUnit () {
      this.renderLineChart()
    },
    changeTempUnit () {
      this.renderLineChart()
    },
    changeGlucoseUnit () {
      this.renderLineChart()
    },
    changeCholesterolUnit () {
      this.renderLineChart()
    }
    // data: {
		// 	immediate: true,
		// 	deep: true,
		// 	async handler (newVal, old) {
    //     if (old !== undefined) {
    //       this.renderLineChart()
    //     }
		// 	},
		// },
  }
};
</script>